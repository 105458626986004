#header {
  margin-bottom: 115px;

  .container-fluid {
    padding: 0;
  }

  .image-wrapper {
    position: relative;
    widows: 100%;
    height: 480px;
    overflow: hidden;
    background-image: url(/images/hero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    
    @media screen and (min-width: $screen-md) {
      height: 760px;
    }

    @media screen and (min-width: $screen-lg) {
      height: 600px;
    }

    img {
      &.mission-badge {
        position: absolute;
        top: 43px;
        right: 10px;
        max-width: 175px;
      }
    }
  }

  .text-wrapper {
    padding: 32px;
    background-image: url(/images/texture_intro_Mission_GRACE-FO.jpg);
    background-repeat: repeat;
    box-shadow: 0px 0px 11px 3px rgba(0,0,0,0.17);

    @media screen and (min-width: $screen-md) {
      margin: -25% auto 0;
      padding: 32px 85px;
    }

    @media screen and (min-width: $screen-lg) {
      margin: -15% auto 0;
      padding: 32px 155px;
    }

    @media screen and (min-width: $screen-xl) {
      margin: -10% auto 0;
    }

    h1 {
      color: $color-2;
    }
  }
}