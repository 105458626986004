$top-spacing: 84px;
$bottom-spacing: 84px;

.closeMetaphor {
  position: relative;
  height: $top-spacing;
  z-index: 2;

  a {
    display: inline-block;
    padding: 25px;
    float: right;
  }
  svg g {
    stroke: #003E6E;
  }
}

.metaphorSlider {
  height: calc(100vh - #{$top-spacing}); // Fallback for browsers that do not support Custom Properties
  height: calc((var(--vh, 1vh) * 100) - #{$top-spacing});
  background-color: #F0F0F0;

  .slick-list, .slick-track {
    height: 100%;
  }

  .slick-slider {
    height: 100%;

    .slick-prev, .slick-next {
      &.slick-disabled {
        display: none !important; 
      }
    }

    .slick-prev,
    .slick-next {
      width: 38px;
      height: 38px;
      z-index: 1;
      top: 38%;
      transform: translate(0,-38%);

      @media screen and (min-width: $screen-md) {
        top: 50%;
        transform: translate(0,-50%);
      }

      &::before {
        content: '';
        height: 38px;
        background-size: 100%;
        background-repeat: no-repeat;
        top: 0;
      }
    }

    .slick-prev {
      left: 10px;

      @media screen and (min-width: $screen-md) {
        left: 25px;
      }

      &::before {
        background-image: url(/images/ICN_arrow_metapher_next.svg);
        transform: scale(-1,1);
      }
    }

    .slick-next {
      right: 10px;

      @media screen and (min-width: $screen-md) {
        right: 45px;
      }

      &::before {
        background-image: url(/images/ICN_arrow_metapher_next.svg);
      }
    }

    .slick-dots {
      height: $bottom-spacing;
      bottom: 0;
      padding-top: 20px;
      background-color: white;

      .slick-active {
        button::before, &:hover button::before {
          color: #359DDC;
        }
      }
      
      button {
        &::before {
          font-size: 12px;
          background-image: none;
        }
      }
    }

    .slick-slide {
      position: relative;
      overflow: hidden;

      .slide-content-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: stretch;
        padding-bottom: $bottom-spacing;
      }

      & > div {
        height: 100%;
        .slide {
          height: 100%;
        }
      }

      h3 {
        width: 100%;
        max-width: 880px;
        margin: 0 auto;
        text-align: center;
        font-weight: 300;
        padding: 0 0.5rem;
        font-size: 1.4rem;

        @media screen and (min-width: $screen-md) {
          font-size: 1.75rem;
        }

        strong {
          font-weight: 600;        
        }
      }

      a {
        display: block; 
        margin-top: 30px;
        text-align: center;
      }

      .slideContent {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        // height: 100%; //calc(100% - 1800px);

        &.skyline {
          background: url(/images/CG34_Skyline_Berlin.png) repeat-x; 
          background-size: 50%;
          background-position: left bottom;
        }

        &.skyline-people {
          background: url(/images/GFZ_einstieg_metapher.svg) no-repeat; 
          background-size: cover;
          background-position: center bottom;
          align-items: baseline;
        }

        p {
          font-size: 50px;
          line-height: 70px;
          text-align: center;
          font-weight: 300;

          @media screen and (min-width: $screen-md) {
            font-size: 60px;
          }
        }

        .line {
          position: absolute;
          left: 50%;
          width: 100px;
          border-bottom: 2px solid $color-1;
          z-index: 1;
          transform: translateX(-50%);
          &:nth-child(1) {
            bottom: 230px;
          }
          &:nth-child(2) {
            bottom: 170px;
          }
          &:nth-child(3) {
            bottom: 110px;
          }
        }
        .ocean {
          height: 180px;
          width: 100%;
          position: absolute;
          bottom: 60px;
          left: 0;
          .wave {
            top: 0px;
            &:nth-of-type(2) {
              top: 135px !important;
          }
          }
        }
      }
    }
  }

  .wave {
    top: -230px;
  }

  .wave:nth-of-type(2) {
    top: -85px;
  }

  .metaphor-icon-text {
    position: relative;

    &__text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 35px;
      line-height: 42px;
      font-weight: 100;
      margin-top: 42px;
      text-align: center;
    }

  }
  
}