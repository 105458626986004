.lightbox-layer {
  &.visible {
    img {
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }
  &-image {
    &-container {
      display: inline-block;
      text-align: center;
      width: auto;
      max-width: 90% !important;
      max-height: 90% !important;

      img {
        position: relative;
        z-index: 10;
      }
    }
  }

  figcaption {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: white;
  }

  .nextLink,
  .prevLink {
    display: none;
    z-index: 11;

    > .MuiSvgIcon-root {
      background-color: $color-6;
    }

    &.active {
      display: block;
    }
  }

  &.visible {
    > div {
      svg {
        &:first-child {
          font-size: 50px !important;
        
          @media screen and (min-width: $screen-md) {
            font-size: 40px !important;
          }
        }
      }

      .nextLink,
      .prevLink {
        svg {
          &:first-child {
            font-size: 40px !important;
          
            @media screen and (min-width: $screen-md) {
              font-size: 30px !important;
            }
          }
        }
      }
    }
  }
}

.MuiSvgIcon-root.lightbox-layer-close {
  width: 1em;
  height: 1em;

  @media screen and (min-width: $screen-md) {
    width: 1em;
    height: 1em;
  }
}

