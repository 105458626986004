button, .button {
  display: inline-block;
  position: relative;
  padding: 16px 60px;
  background-color: $color-2;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: $white;
  outline: none;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $color-6;
    cursor: pointer;
    text-decoration: none;

    &::before {
      left: 10px;
    }
  }

  &::before {
    display: block;
    position: absolute;
    left: 0;
    top: 25px;
    width: 38px;
    height: 14px;
    content: '';
    background-image: url(/images/ICN_arrow_in_buttons.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    color: $color-6;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &.secondary {
    color: $color-2;
    background-color: $white;
    border: 1px solid $color-6;

    &:hover {
      color: $color-6;
    }
  }

  &.textlink {
    padding-right: 40px;  
    color: $color-2;
    background-color: transparent;
    
    &:hover {
      color: $color-6;
    }
  }
}