#iframe {
  margin-bottom: 115px;

  .container {
    max-width: 1280px;
  }

  iframe {
    width: 100%;
    height: 820px;
    border: 0;
  }

  .iFrameHolder {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}