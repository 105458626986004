h1, h2, h3, h4 {
  @media screen and (max-width: $mobile-small-max) {
    hyphens: auto;
  }
}

body {
  h1 {
    font-size: 28px;
    font-weight: 400;
  }
  h2 {
    font-size: 28px;
    font-weight: 400;
  }
  h3 {
    font-size: 28px;
    font-weight: 400;
  }
  h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
  }
  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
  }
}

.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}