.sociallinks {
  margin-bottom: 100px;

  h2 {
    color: $color-1;
    margin-bottom: 26px;
  }

  &-link {
    margin: 0 30px;
    font-size: 2.6em;
    color: #000000;

    &:hover {
      color: $color-6;
    }
  }
}