#faq {
  margin-bottom: 115px;

  h2 {
    color: $color-2; 
  }

  .accor {
    margin: 10px;
    border-bottom: 1px solid #D9DADA;
    transition: 0.4s;
    
    &.active {
      .head::after {
        transform: scale(1,-1);
      }
    }
  
    .head {
      position: relative;
      padding: 24px 50px 24px 20px;
      cursor: pointer;
      font-size: 18px;
      color: black;

      &::after {
        display: block;
        content: '';
        width: 19px;
        height: 19px;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        transform-origin: 7px 5px;
        background-image: url("/images/ICN_arrow_akkordeon.svg");
        background-size: cover;
        background-repeat: no-repeat;
        transition: all .3s ease-in-out;
      }
    }

    .body {
      padding: 0 20px;
      max-height: 0;
      overflow: hidden;
      transition: 200ms ease-in-out;
      font-weight: 300;
      font-size: 18px;
    }

    &.active > .body {
      padding: 0px 20px 34px;
      max-height: 2000px;
    }
  }
} 