#interactive {
  position: relative;
  overflow: hidden;
  margin-bottom: 115px;
  padding: 20px 0 460px;
  background-image: url(/images/GFZ_einstieg_metapher.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  background-color: $white;

  @media screen and (min-width: $screen-md) {
    padding-top: 60px;
  }

  .ocean {
    height: 120px;
  }
}