.gfz-select {
  display: block;
  font-size: 19px;
  line-height: 1.3;
  padding: 15px 50px 15px 15px;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23359DDC'><polygon points='0,0 100,0 50,50'/></svg>");
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 64%, 0 0;
  background-size: 1em auto, 100%;

  &::-ms-expand {
      display: none;
  }

  &:hover {
      border-color: #888;
  }

  &:focus {
    outline: none;
  }

  & option {
      font-weight:normal;
  }
}