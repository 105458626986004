.teaser {
  &-container {
    margin-bottom: 30px;

    h2 {
      color: $color-2;
      margin-bottom: 26px;
    }
  }

  &-image {
    width: 100%;
    margin-bottom: 40px;

    &-clickable {
      cursor: pointer;

      @media screen and (min-width: $screen-md) {
        backface-visibility: hidden;
        transition: all .3s ease-in-out;
    
        &:hover {
          transform: scale(0.98);
        }
      }
    }

    &-wide {
      @media screen and (min-width: $screen-lg) {
        margin-bottom: 0;
      }
    }
  }

  &-headline {
    color: $color-2;
    line-height: 35px;
  }
}