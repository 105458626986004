body {
  .navbar {
    padding: 1rem;

    body & {
      @media screen and (min-width: $screen-lg) {
        padding: .5rem 3rem;
      }
    }

    &-light {
      background-color: $white !important;
      .navbar-nav {
        .nav-link {
          position: relative;
          color: $color-1;
          font-size: 1.125rem;

          @media screen and (max-width: $screen-lg) {
            padding-left: 30px;
            color: white;
            line-height: 36px;
          }

          @media screen and (min-width: $screen-lg) {
            margin: 0 10px;
          }

          @media screen and (min-width: $screen-xl) {
            margin: 0 30px;
          }

          &:hover {
            color: $color-6;
          }

          @media screen and (max-width: $screen-lg) {
            &::before {
              background-image: url(/images/ICN_arrow_metapher_next.svg);
              background-repeat: no-repeat;
              background-size: cover;
              display: inline-block;
              content: '';
              width: 20px;
              height: 24px;
              margin-right: 5px;
              position: absolute;
              left: 3px;
              top: 13px;
            }
          }

          &-lang {
            border: 1px solid $color-6;
            margin: 30px 0 0 30px;
            padding: 0.2rem 1rem;
            display: inline-block;

            &:before {
              display: none;
            }

            @media screen and (min-width: $screen-lg) {
              padding: 0.5rem 1rem;
              margin: 0 10px;
            }
          }
        }
      }
    }
    a.navbar-brand {
      @media screen and (min-width: $screen-lg) {
        svg {
          height: 100px;
          width: auto;
        }
      }
    }
  }
}
.navbar-nav {
  width: 100%;
}

#basic-navbar-nav {
  @media screen and (max-width: $screen-lg) {
    max-width: 380px;
    width: 100%;
    padding: 20px;
    position: absolute;
    right: 0px;
    top: 108px;
    background-color: $color-2;
    z-index: 1;
  }
}

.navbar-toggler {
  border: none !important;
  color: $color-6;
  width: 32px;
  height: 22px;
  padding: 0;
  margin-right: 15px;

  &::before {
    width: 100%;
    height: 100%;
    background: none;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    top: auto;
    left: auto;
  }

  > span {
    background: none;
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $color-6;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }
    
    &:nth-child(2),
    &:nth-child(3) {
      top: 10px;
    }
    
    &:nth-child(4) {
      top: 20px;
    }    
  }

  &.collapsed > span {
    &:nth-child(1) {
      top: 10px;
      width: 0%;
      left: 50%;
    }
  
    &:nth-child(2) {
      transform: rotate(45deg);
    }
    
    &:nth-child(3) {
      transform: rotate(-45deg);
    }
    
    &:nth-child(4) {
      top: 10px;
      width: 0%;
      left: 50%;
    }
  }
}

