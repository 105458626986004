footer {
  position: relative;
  padding: 115px 0;
  background-color: $white;

  a {
    color: $color-1;
  }

  img {
    width: 170px;
  }

  .row {
    text-align: center;

    @media screen and (min-width: $screen-lg) {
      text-align: auto;
    }

    > div {
      margin-bottom: 60px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (min-width: $screen-lg) {
        margin-bottom: 0;
      }
    }
  }

  .backToTop {
    position: absolute;
    top: -40px;
    right: 20px;
    background-color: $color-6;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: $screen-md) {
      right: 80px;
    }

    img {
      width: 40%;
    }

    &.is-sticky {
      position: fixed;
      bottom: 20px;
      top: auto;
    }
  }
}