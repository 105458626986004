$color-1: #003e6e;
$color-2: #00589C;

$color-3: #B9B9B9;
$color-4: #9C9C9C;
$color-5: #515151;

$color-6: #359DDC;
$color-7: #F73F07;
$color-8: #5DDAE9;

$color-bg:        #F0F0F0;
$color-line:      #E1E2E3;
$color-breadcrumb:  #E1E2E3;
$color-footer:      #ECECED;  

$white: white;
$black: black;