// Bootstrap
@import 'styles/custom';

// Base
@import 'styles/base/colors';
@import 'styles/base/breakpoints';
@import 'styles/base/webfonts';
@import 'styles/base/font-variables';

// Partials
@import 'styles/partials/button';
@import 'styles/partials/nav';
@import 'styles/partials/hero';
@import 'styles/partials/footer';
@import 'styles/partials/imagetext';
@import 'styles/partials/interactive';
@import 'styles/partials/iframe';
@import 'styles/partials/lightbox';
@import 'styles/partials/faq';
@import 'styles/partials/teaser';
@import 'styles/partials/sociallinks';
@import 'styles/partials/metaphorSlider';
@import 'styles/partials/metaphorTool';
@import 'styles/partials/ocean';
@import 'styles/partials/select';
@import 'styles/partials/impressum';
@import 'styles/partials/datenschutz';


#root {
  background-color: #F0F0F0;
}

svg path {
  pointer-events: none;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: $color-3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h2 {
  color: $color-2;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
