.ocean { 
  height: 0;
  width:100%;
  position:absolute;
  bottom:0;
  left:0;
  // background: #015871;
}

.wave {
  // background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x; 
  background: url(/images/wave.svg) repeat-x; 
  position: absolute;
  // top: -198px;
  top: 0;
  width: 6400px;
  height: 100%;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.4;
}

.wave:nth-of-type(2) {
  // top: -55px;
  top: 30px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
}