
.metaphorTool {
  $top-spacing: 84px;

  position: relative;
  height: calc(100vh - #{$top-spacing}); // Fallback for browsers that do not support Custom Properties
  height: calc((var(--vh, 1vh) * 100) - #{$top-spacing});
  background-color: $color-bg;
  //overflow: hidden;

  .hidden {
    display: none;
  }

  .toolContent {
    height: calc(100% - 78px); 
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    &.people {
      background: url(/images/Silhouette_Frau_Mann.svg) no-repeat; 
      background-size: auto 50%;
      background-position: center bottom;
      justify-content: space-between;
    }

    &.last-slide {
      &:before {
        content: '';
        speak: none;
        position: absolute;
        top: $top-spacing * -1;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(to bottom, #57bbc1 25%,#015871 100%);
      }

      > div:not(.toolFooter) {
        position: relative;
        z-index: 1;
      }
    }

    .toolHeader {
      width: 100%;

      &.hidden {
        display: none;
      }

      @media screen and (min-width: $screen-md) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
      }

      .gfz-select-container {
        flex: 1 0 auto;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        @media screen and (min-width: $screen-md) {
          justify-content: flex-end;
          flex: 1 0 50%;
          margin-bottom: 0;
        }
      }

      .calculatedHeight {
        flex: 1 0 auto;
        font-size: 40px;
        font-weight: bold;
        line-height: 50px;
        text-align: center;

        @media screen and (min-width: $screen-md) {
          font-size: 70px;
          text-align: left;
          padding-left: 50px;
          flex: 1 0 50%;
        }
      }
    }

    .text {
      width: 90%;
      margin: 0 auto;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      font-weight: 300;

      @media screen and (min-width: $screen-md) {
        font-size: 30px;
        line-height: 44px;
        width: 70%;
      }
    }

    .image-wrapper {
      margin-top: 20px;

      img {
        &.mission-badge {
          max-width: 110px;

          @media screen and (min-width: $screen-md) {
            max-width: 175px;
          }
        }
      }
    }

    .toolFooter {
      position: absolute;
      bottom: -78px;
      left: 0;
      height: 78px;
      width: 100%;
      background-color: white;
      z-index: 1;

      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        list-style: none;
        height: 100%;
        margin: 0;
        padding: 0;

        li {
          margin: 0 5px;
          padding: 0 5px;
          line-height: 1;

          @media screen and (min-width: $screen-md) {
            margin: 0 10px;
            padding: 0 10px;
          }

          &:hover {
            cursor: pointer;
            color: $color-6;
            font-weight: bold;
          }

          &.active {
            color: $color-6;
            font-weight: bold;
            font-size: 1.2rem;
            
            @media screen and (min-width: $screen-md) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  } 

  .ocean {
    max-height: 90%;
    transition: all 0.4s ease 0s !important;
  }
}