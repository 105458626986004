.imagetext {
  .row {
    margin-bottom: 115px;
  }
}

.lightbox-layer.visible {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  & > div {
    background-color: rgba(0, 0, 0, 0.75) !important;
    
    svg:first-child {
      font-size: 60px !important;
    }
  }

  img {
    width: auto;
    max-width: 90% !important;
    max-height: 90% !important;
  }

  iframe {
    max-width: 90% !important;
    max-height: 90% !important;

    @media screen and (min-width: $screen-lg) {
      height: 81%;
      width: 90%;
    }
  }
}